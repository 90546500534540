export default defineAppConfig({
  ui: {
    primary: 'heroblue',
    gray: 'stone',

    tooltip: {
      popper: {
        arrow: true
      },
      base: 'text-sm h-7 font-semibold',
      background: 'bg-charcoal-600 dark:bg-white',
      color: 'text-white dark:text-charcoal-600',
      ring: 'ring-0',
      arrow: {
        background: 'before:bg-charcoal-600 dark:before:bg-white',
        rounded: 'before:rounded-none',
        ring: 'before:ring-0'
      },
      default: {
        openDelay: 100,
        closeDelay: 50,
      }
    },

    dropdown: {
      popper: {
        arrow: true
      },
      background: 'bg-charcoal-200 dark:bg-charcoal-800',
      divide: 'divide-charcoal-300/60',
      ring: 'dark:ring-charcoal-600',
      shadow: 'shadow-none dark:shadow-lg',
      rounded: 'rounded',
      width: 'min-w-48 w-auto max-w-64',
      item: {
        base: 'font-semibold transition-colors gap-2',
        padding: 'px-2 py-1',
        active: 'bg-white text-charcoal-700 dark:bg-black/70',
        inactive: 'text-charcoal-500/85',
        icon: {
          base: 'scale-90',
          active: 'text-charcoal-600 dark:text-white',
          inactive: 'text-charcoal-500/85 dark:text-white/75'
        }
      },
      arrow: {
        base: 'before:w-3 before:h-3',
        shadow: 'before:shadow-none',
        ring: 'before:ring-charcoal-200',
        background: 'before:bg-charcoal-200 dark:before:bg-charcoal-800',
      }
    },

    avatar: {
      placeholder: 'text-white',
      size: {
        '4xl': 'h-28 w-28 text-4xl',
        '5xl': 'h-36 w-36 text-4xl'
      }
    },

    input: {
      rounded: 'rounded-none',
      default: {
        size: 'md',
        loadingIcon: 'i-ri-loop-left-line'
      }
    },

    textarea: {
      rounded: 'rounded-none',
      default: {
        size: 'md'
      }
    },

    checkbox: {
      label: 'font-semibold'
    },

    formGroup: {
      label: {
        base: 'font-semibold text-base'
      }
    },

    button: {
      font: 'font-semibold',
      rounded: 'rounded',
      variant: {
        link: 'no-underline'
      },
      default: {
        loadingIcon: 'i-ri-loop-left-line'
      }
    },

    notification: {
      title: 'font-semibold text-base'
    },

    configMenu: {
      default: {
        showCreateOptionWhen: 'always'
      }
    },

    alert: {
      padding: 'p-2.5',
      rounded: 'rounded',
      title: 'font-semibold',
      default: {
        variant: 'soft'
      }
    },

    selectMenu: {
      rounded: 'rounded-none',
      default: {
        selectedIcon: 'i-ri-check-line'
      },
      option: {
        active: 'bg-ice-200',
        selectedIcon: {
          base: 'text-sage-800'
        }
      },
      popper: {
        arrow: true
      }
    },

    select: {
      rounded: 'rounded',
      default: {
        loadingIcon: 'i-ri-loop-left-line',
        trailingIcon: 'i-ri-arrow-down-s-line'
      }
    },

    accordion: {
      default: {
        openIcon: 'i-ri-arrow-down-s-line'
      }
    },

      badge: {
      default: {
        variant: 'subtle'
      }
    },

    popover: {
      overlay: {
        background: 'bg-black/50'
      }
    },

    slideover: {
      overlay: {
        background: 'bg-black/50'
      }
    }
  }
});

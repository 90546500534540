import { default as indexwtYW3W77xGMeta } from "/tmp/build_fda0b3f1/pages/connections/index.vue?macro=true";
import { default as indexhIQz8FLlOUMeta } from "/tmp/build_fda0b3f1/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_937keCWu4pSGMeta } from "/tmp/build_fda0b3f1/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_930EFjgoCaKBMeta } from "/tmp/build_fda0b3f1/pages/gallery/[file_id].vue?macro=true";
import { default as galleryeuA2zRCBvpMeta } from "/tmp/build_fda0b3f1/pages/gallery.vue?macro=true";
import { default as indexTChOONxvKjMeta } from "/tmp/build_fda0b3f1/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_93NS74E0ZlR4Meta } from "/tmp/build_fda0b3f1/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45inXVsR0AIXOFMeta } from "/tmp/build_fda0b3f1/pages/log-in.vue?macro=true";
import { default as log_45outBF2BBbtMqDMeta } from "/tmp/build_fda0b3f1/pages/log-out.vue?macro=true";
import { default as indexk1NowgftAjMeta } from "/tmp/build_fda0b3f1/pages/notifications/index.vue?macro=true";
import { default as projects4rksfrit0zMeta } from "/tmp/build_fda0b3f1/pages/projects.vue?macro=true";
import { default as _91file_id_93KyVktgoKfRMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93ZxujNp0F5pMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as index7zH9Jx3AYuMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedQ1A4Vlp76RMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_930eQJ7PBFksMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93EQJKex5fr1Meta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93N2KozrjeKwMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as indexquwP2AxEYyMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_936DaxRImSRqMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93FiYmGYWvRIMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexS3T5cGinxVMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93OemhwJ3dcuMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectsOT2KpmZTabMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_937tgvtXNjQZMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93WjKpCoxOiZMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexDLk9PT6xkPMeta } from "/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomedgc3Srx3QgMeta } from "/tmp/build_fda0b3f1/pages/welcome.vue?macro=true";
import { default as component_45stubVliN8YxdoLMeta } from "/tmp/build_fda0b3f1/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubVliN8YxdoL } from "/tmp/build_fda0b3f1/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: indexwtYW3W77xGMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexhIQz8FLlOUMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_fda0b3f1/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryeuA2zRCBvpMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_fda0b3f1/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexTChOONxvKjMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_fda0b3f1/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_fda0b3f1/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_fda0b3f1/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexk1NowgftAjMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projects4rksfrit0zMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: index7zH9Jx3AYuMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedQ1A4Vlp76RMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexS3T5cGinxVMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectsOT2KpmZTabMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexDLk9PT6xkPMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomedgc3Srx3QgMeta || {},
    component: () => import("/tmp/build_fda0b3f1/pages/welcome.vue")
  },
  {
    name: component_45stubVliN8YxdoLMeta?.name,
    path: "/",
    component: component_45stubVliN8YxdoL
  },
  {
    name: component_45stubVliN8YxdoLMeta?.name,
    path: "/library",
    component: component_45stubVliN8YxdoL
  },
  {
    name: component_45stubVliN8YxdoLMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubVliN8YxdoL
  }
]
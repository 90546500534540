export const COMMON_ICONS = {
  //entities
  photo: 'i-ri-image-line',
  gallery: 'i-ri-image-2-line',
  tag: 'i-ri-price-tag-3-line',
  album: 'i-ri-book-2-line',
  nestedAlbum: 'i-hugeicons-hierarchy-square-01',
  person: 'i-ri-user-line',
  group: 'i-ri-group-line',
  project: 'i-ri-screenshot-2-line',
  connection: 'i-ri-open-arm-line',
  deleteBin: 'i-ri-delete-bin-2-line',
  boxCenter: 'i-ri-inbox-line',

  //actions
  share: 'i-ri-share-line',
  add: 'i-ri-add-line',
  close: 'i-ri-close-line',
  delete: 'i-ri-delete-bin-2-line',
  copy: 'i-ri-folders-line',
  edit: 'i-ri-edit-2-line',
  slideshow: 'i-ri-movie-2-line',
  declutter: 'i-ri-file-copy-line',
  print: 'i-ri-shopping-bag-4-line',
  download: 'i-ri-download-2-line',
  upload: 'i-ri-upload-2-line',
  makeCover: 'i-ri-image-2-line',
  signIn: 'i-ri-upload-line',
  addFiles: 'i-ri-image-add-line',
  personShow: 'i-ri-eye-line',
  personHide: 'i-ri-eye-off-line',
  favoriteLine: 'i-ri-heart-line',
  favoriteFill: 'i-ri-heart-fill',
  moreVertical: 'i-ri-more-2-fill',
  moreHorizontal: 'i-ri-more-fill',
  moveDrag: 'i-ri-drag-move-2-line',
  help: 'i-ri-question-fill',

  //symbols
  noFiles: 'i-ri-file-warning-line',
  loading: 'i-ri-loader-4-line',
  facialRecognition: 'i-lucide-focus',
  information: 'i-ri-information-line'
}
